.carousel-indicators{
  display: none;
}
.carousel-control-prev{
  display: none;
}
.carousel-control-next{
  display: none;
}
.col-md-6{
  color: white;
  p{
    margin-bottom:0;
  }
}
.reviews-header{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.review-text{
  font-size: 1.3em;
}
.review-author {
  font-size: 1.2em;
  font-weight: bold;
}
@media screen and (max-width: 1440px){
  .reviews-header{
    font-size: 1.5em;
    padding-top: 10px;
  }
  .review-container{
    font-size: 0.8em;
    padding-bottom: 20px;
  }
    
  }
  