.card-body {
background-color: #202428;
.page-start
{
  background-color: #202428;
}
}
#page-content{
  background-color: #202428;
}

.booking-widget-container {
  margin-top: 50px;
  margin-bottom: -25px;
  z-index: 1;
}

@media (max-width: 767px) {
  .booking-widget-container {
    margin-top: 54px;
    margin-bottom: 0px;
  }
}