@font-face {
  font-family: 'Nineteenth';
  src: url('../fonts/Nineteenth.woff2') format('woff2'),
  url('../fonts/Nineteenth.woff') format('woff');
}

.slogan-crusoe_container{
  width:100%;
  height: 115px;
  background: #0C0C0C;
  z-index: 2;
  position: absolute;
}
.slogan-crusoe_texts{
  font-family: Nineteenth;
  padding: 20px 20px;
  text-align: center;
  h3{
    text-transform: uppercase;
    color: #B4813E;
  }
  p{
    color:#686868;
  }
}