@font-face {
  font-family: 'Nineteenth';
  src: url('../fonts/Nineteenth.woff2') format('woff2'),
  url('../fonts/Nineteenth.woff') format('woff');
}

.together-container{
  display: flex;
  justify-content: space-evenly;
align-items: center;
overflow: hidden;
}

@media screen and (max-width: 700px){
  .together-container{
 flex-direction: column-reverse;
 height: 900px;
  }
    }
.together-text_container{
  font-family: Nineteenth;
  color: white;
  text-align: justify;
    text-justify: inter-word;
  width: 25%;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  transform: translateY(100%); 
  transition: transform 0.5s ease-in-out;
  h3{
  font-size: 3.5em;
    }
  p{
  font-size: 1.3em;
  line-height: 2em;
    }
}

.together-photo_container.show  {
  transform: translateY(0%); 
}
.together-text_container.show{
  transform: translateY(0%); 
}

@media screen and (max-width: 1170px){
  .together-text_container{
    
    h3{
      font-size:2.5em;
    }
    p{
      font-size: 1em;
      line-height: 1.5em;
    }
}
}
@media screen and (max-width: 700px){
  .together-text_container{
    width: 100%;
    h3{
      font-size:2em;
    }
    p{
      padding: 20px;
      font-size: 1em;
      line-height: 1.1em;
    }
}
}
.together-photo_container {
  position: relative;
  height: 510px;
  width: 500px;
  border-radius: 20px;
  overflow: hidden;
  transform: translateY(-100%); 
  transition: transform 1s ease-in-out;
}

.instagram-widget {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


@media screen and (max-width: 1170px){
  .together-photo_container{
    height: 460px;
    width: 380px;
  }
}
@media screen and (max-width: 700px){
  .together-photo_container{
    height: 500px;
    width: 380px;
  }
}