.highlights-container{
  background: linear-gradient(0.25turn, #0B0B0B, #3F3F3F);
  width:100%;
  height: 100px;
  padding-right: 39%;

  padding-top: 20px;
}
.highlights-icons{
  display: flex;
  justify-content: space-evenly;
  height: 100px;
  width: 100%;

  .highlights-wifi{
    height: 45px;
    width: 60px;
    background-size: cover;
  }
  .highlights-sofa{
    height: 60px;
    width: 63px;
    background-size: cover;
  }
  .highlights-food{
    height: 55px;
    width: 60px;
    background-size: cover;
  }
  .highlights-washer{
    height: 60px;
    width: 54px;
    background-size: cover;
  }
  .highlights-clima{
    height: 60px;
    width: 54px;
    background-size: cover;
  }
  .highlights-krakow {
    height: 60px;
    width: 54px;
    background-size: cover;
  }
}
@media screen and (max-width: 700px){
  .highlights-container{
    padding: 30px 0;
  }
  .highlights-icons{

    .highlights-wifi{
      height: 25px;
      width: 35px;
    }
    .highlights-sofa{
      height: 32px;
      width: 35px;
      background-size: cover;
    }
    .highlights-food{
      height: 35px;
      width: 35px;
      background-size: cover;
    }
    .highlights-washer{
      height: 35px;
      width: 30px;
      background-size: cover;
    }
    .highlights-clima{
      height: 35px;
      width: 32px;
      background-size: cover;
    }
    .highlights-krakow {
      height: 35px;
      width: 32px;
      background-size: cover;
    }
  }
}